import { navigate, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { InlineWidget } from "react-calendly";
import Layout from '~/components/Layout';
import Image from '~/components/atoms/Image';
import CustomSEO from '~/components/seo/CustomSEO';
import { pricingResultsLabels } from '~/data/translations/pricingFormLabels';
// import { StaticImage } from 'gatsby-plugin-image';
import './pricing-result.css';

export default function PricingResult(props) {

  const {
    location: { state },
    data: { wpPage },
  } = props;

  const section = wpPage?.pricingResult?.pricingResult

  let cityName = "not_paris";//default value
  if (state && state.cityName) {
    cityName = state.cityName;//values can be: paris , not_paris
  }

  // if paris => default = shiraz
  // if not paris => default = akim
  let widgetUrl = cityName == "paris"
    ? "https://meetings.hubspot.com/shiraz-osman?embed=true"
    : "https://meetings.hubspot.com/akim-courail?embed=true";

  const [isAgentSelected, setIsAgentSelected] = useState(false); // default is false
  const [selectedAgent, setSelectedAgent] = useState(null);

  const onSelectAgent = (agent) => {
    setSelectedAgent(agent);
    setIsAgentSelected(true);
  };

  useEffect(() => {

  }, [selectedAgent]); // This runs whenever selectedAgent changes

  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);

  useEffect(() => {
    if (typeof state?.estimate === 'undefined') {
      return navigate('/pricing/');
    }
    else {
      setXValue(Math.round(state.estimate * 1.1 / 10) * 10);
      setYValue(Math.round(state.estimate * 1.3 / 10) * 10);
    }
  }, []);

  const resultsLabels = {
    "en": {
      "heading": section?.heading,
      "income": "Potential income per month",
      "disclaimer": section?.disclaimer,
      "assumptionsHeading": section?.assumptions?.heading,
      "assumptions": section?.assumptions?.assumptions?.map((item) => (item.assumption)),
      "calendlyHeading": section?.calendlyHeading,
      "currency": "£",
    },
    ...pricingResultsLabels,
  }

  const labels = resultsLabels[state?.language || 'en'];

  // Do NOT move this code up
  if (selectedAgent) {
    if (selectedAgent == 'akim') {
      widgetUrl = "https://meetings.hubspot.com/akim-courail?embed=true";
    }
    else if (selectedAgent == 'dominique') {
      // widgetUrl = "https://meetings.hubspot.com/dominique-laretive?uuid=ad1b31f3-76ac-4828-af3d-3803a6dc911c";
      widgetUrl = "https://meetings.hubspot.com/dominique-laretive?embed=true";
    }
    else {
      widgetUrl = "https://meetings.hubspot.com/shiraz-osman?embed=true";
    }
  }

  return state?.estimate ? (
    <Layout>
      <Seo post={wpPage} />
      <CustomSEO seo={wpPage?.seo} />
      <section className="my-20 md:my-36">
        <div className="container">
          <h1 className="text-h1 text-center mb-12">{labels.heading}</h1>

          <div className="grid grid-cols-1 md:grid-cols-12 gap-8 max-w-5xl mx-auto w-full">
            <div className="col-span-1 md:col-span-5 flex flex-col justify-center py-16 px-6 bg-navy-dark text-white">
              {state?.estimate && (
                <div className="space-y-3 mb-12 md:mb-16 text-center max-w-xs mx-auto">
                  <h2 className="text-h6">{labels.income}</h2>
                  {/* <h3 className="text-h2">{labels.currency}{state?.estimate}</h3> */}
                  <h3 className="text-h2">{labels.currency}{xValue} - {labels.currency}{yValue}</h3>
                  <p className="opacity-40 text-xs">{labels.disclaimer}</p>
                </div>
              )}

              {section.assumptions && (
                <div className="max-w-xs mx-auto">
                  <h4 className="text-h5 mb-6 text-center">{labels.assumptionsHeading}</h4>
                  <div className="flex flex-col justify-center">
                    <div className="text-sm space-y-3 max-w-[16rem]  mx-auto">
                      {section.assumptions.assumptions?.map((item, i) => (
                        <div key={`assumption${i}`} className="grid grid-cols-12 gap-4 items-center">
                          <div className="col-span-3">
                            <div className="aspect-w-1 aspect-h-1">
                              <div>{item.illustration && <Image data={item.illustration} />}</div>
                            </div>
                          </div>
                          <div className="col-span-9">{labels.assumptions[i]}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

            </div>

            <div className="col-span-1 md:col-span-7 bg-white p-10 border border-opacity-5 border-black flex flex-col">
              {section.calendlyHeading && <h3 className="text-h4 mb-8">{labels.calendlyHeading}</h3>}

              {/* if agent not selected yet AND city is NOT paris => show 2 agents to select one */}
              {
                !isAgentSelected && cityName == "not_paris" &&
                <div className="custom1">
                  <label class="text-h5">Select your agent</label>

                  <div id="agents">
                    <div id="agent1" onClick={() => onSelectAgent('akim')}>
                      <img className="responsive-img" />
                      <span className='text-h3'>Akim Courail-Jones</span>
                    </div>

                    <div id="agent2" onClick={() => onSelectAgent('dominique')}>
                      <img className="responsive-img" />
                      <span className='text-h3'>Dominique Laretive</span>
                    </div>
                  </div>

                </div>
              }

              {/* if the agent is already selected OR city is paris */}

              {(isAgentSelected || cityName == "paris") && <div className="bg-seafoam flex-auto p-5 flex items-center justify-center custom2">
                <InlineWidget
                  styles={{
                    width: '100%',
                    // height: '38rem'
                    // height: '450px'
                  }}
                  pageSettings={{
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                  }}
                  url={widgetUrl}
                  // url="https://meetings.hubspot.com/akim-courail?embed=true%22"
                  prefill={{
                    email: state?.email || '',
                    name: state?.full_name || '',
                  }}
                />
              </div>}

            </div>
          </div>

        </div>
      </section>
    </Layout>
  ) : null
}

export const pageQuery = graphql`
  query pricingResultQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      pricingResult {
        pricingResult {
          assumptions {
            heading
            assumptions {
              illustration {
                ... Image
              }
              assumption
            }
          }
          disclaimer
          heading
          calendlyHeading
        }
      }
    }
  }
`
